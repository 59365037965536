<template>
  <div>
    <div id="d32DGraph" ref="graph" />
    <AddEntityModal @added="postAdd" />
    <DeleteEntityModal @deleted="postDel" />
  </div>
</template>
<script>
import * as d3 from 'd3'
import { mapState } from 'vuex'
import AddEntityModal from '@/components/Domain/Modals/AddEntity.vue'
import DeleteEntityModal from '@/components/Domain/Modals/DeleteEntity.vue'

export default {
  name: 'EcosystemView',
  components: {
    AddEntityModal,
    DeleteEntityModal,
  },
  data: () => ({
    root_nm: '',
    root: null,
    nodes: {},
    links: {},
    selParent: null,

    node_lookup: {},
  }),

  computed: {
    ...mapState('domainModel', ['composition_tree']),
    ...mapState({
      props: state => state.model.lookup,
    }),
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    composition_tree() {
      this.clearSVG()
      this.generate2DGraph()
    },
  },

  created() { },

  mounted() {
    let { root } = this.$route.params
    if (['domain_function', 'domain_analysis', 'domain_analysis_focus'].includes(this.currentRouteName)) {
      root = this.props.Functions
    }
    this.$store.dispatch('domainModel/getCompTreeDataD3', root || null)
      .then(() => {
        this.clearSVG()
        this.generate2DGraph()
      })
  },

  updated() {
    this.$store.dispatch('domainModel/getCompTreeDataD3', this.$route.params.id || null)
      .then(() => {
        this.clearSVG()
        this.generate2DGraph()
      })
  },

  methods: {
    generate2DGraph() {
      const vueApp = this
      const rootNM = this.composition_tree.root

      const { nodes } = this.composition_tree
      const links = this.composition_tree.edges

      // get config
      const configuration = this.composition_tree.config

      // Create a node lookup
      let cptID

      for (let i = 0; i < nodes.length; i++) {
        cptID = nodes[i].id

        // create map of cpt_id -> cpt_detail
        vueApp.node_lookup[cptID] = nodes[i]
      }

      const graph = { nodes, links }

      const treeLinks = [...links]

      treeLinks.push({ source: '', target: rootNM })

      // console.debug('nodes', treeLinks)

      const treeData = d3
        .stratify()
        .id(d => d.target)
        .parentId(d => d.source)(treeLinks)
      // console.debug('Tree Data')
      // console.debug(treeData)

      // panning variables
      const panSpeed = 200
      const panBoundary = 20 // Within 20px from edges will pan when dragging.

      // Set the dimensions and margins of the diagram
      //
      //  height = $(document).height() - margin.top - margin.bottom;

      const element = this.$refs.graph
      const positionInfo = element.getBoundingClientRect()
      let { height } = positionInfo
      const { width } = positionInfo

      const margin = {
        top: 30, right: 80, bottom: 30, left: 100,
      }
      height = window.innerHeight - (window.innerHeight * 0.2)
      // append the svg object to the body of the page
      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top left margin
      // removed .on("dblclick.zoom", null)
      const svg = d3
        .select(this.$refs.graph)
        .append('svg')
        .attr('id', 'graph_svg')
        .attr('width', width)
        .attr('height', height)
        .call(
          d3.zoom().on('zoom', () => {
            svg.attr('transform', d3.event.transform)
          }),
        )
        .append('g')
        .attr('id', 'everything')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      let i = 0
      const duration = 750

      // var root;

      // declares a tree layout and assigns the size
      const treemap = d3.tree().size([height, width])

      const vRoot = d3.hierarchy(treeData)
      const vNodes = vRoot.descendants()

      // Assigns parent, children, height, depth
      const root = d3.hierarchy(treeData)
      root.x0 = height / 2
      root.y0 = margin.left * 3

      // Collapse after the second level
      if (root.children) root.children.forEach(collapse)

      update(root)

      function collapse(d) {
        if (d.children) {
          d._children = d.children
          d._children.forEach(collapse)
          d.children = null
        }
      }

      // Context menu constructor
      function menuFactory(x, y, menuItems, data, svgId) {
        d3.select('.contextMenu').remove()

        // Create the menu
        d3.select(svgId)
          .append('g')
          .attr('class', 'contextMenu')
          .selectAll('tmp')
          .data(menuItems)
          .enter()
          .append('g')
          .attr('class', 'menuEntry')
          .style({ cursor: 'pointer' })

        // Menu entries
        d3.selectAll('.menuEntry')
          .append('rect')
          .attr('x', x)
          .attr('y', (d, i) => y + i * 30)
          .attr('rx', 2)
          .attr('width', 150)
          .attr('height', 30)
          .on('click', d => {
            d.action(data)
          })

        d3.selectAll('.menuEntry')
          .append('text')
          .text(d => d.title(data))
          .attr('x', x)
          .attr('y', (d, i) => y + i * 30)
          .attr('dy', 20)
          .attr('dx', 75)
          .attr('text-anchor', 'middle')
          .on('click', d => {
            d.action(data)
          })

        // Cancel when click out
        d3.select('body').on('click', () => {
          d3.select('.contextMenu').remove()
        })
      }

      // Context menu function call
      function contextMenu(d, menuItems, menuWidth, menuHeight, svgId, coords) {
        // For node
        menuFactory(d.y, d.x, menuItems, d, svgId)
        d3.event.preventDefault()
      }

      function update(source) {
        // Assigns the x and y position for the nodes
        const treeData = treemap(root)

        // Compute the new tree layout.
        const nodes = treeData.descendants()
        const links = treeData.descendants().slice(1)

        // Normalize for fixed-depth.
        nodes.forEach(d => {
          d.y = d.depth * 300
        })

        // ****************** Nodes section ***************************

        // Update the nodes...
        // eslint-disable-next-line no-unused-expressions
        const node = svg.selectAll('g.node').data(nodes, d => { d.id || (d.id = ++i) })

        // Enter any new modes at the parent's previous position.
        const nodeEnter = node
          .enter()
          .append('g')
          .attr('class', 'node')
          .attr('transform', d =>
            // console.warn("Meant to be something: ", d);
            // eslint-disable-next-line implicit-arrow-linebreak
            `translate(${source.y0},${source.x0})`)
          // .on("dblclick",function(d){ alert("node was double clicked"); })
          .on('click', click)
          .on('contextmenu', d => {
            // Prevents default right click behaviour of the browser
            d3.event.preventDefault()
            contextMenu(
              d,
              nodeMenuItems,
              width,
              height,
              '#everything',
              d3.mouse(d3.event.currentTarget),
            )
          })

        // Add Circle for the nodes
        nodeEnter
          .append('circle')
          .attr('class', d => {
            // console.warn("Node Data: ", d.data);
            if (d.data.id === rootNM) return 'root'
            return d._children || d.children ? 'abstract' : 'node'
          })
          .attr('r', 1e-6)

        // Add hover text
        nodeEnter.append('title').text(d => {
          const nodeDetails = vueApp.node_lookup[d.data.id]
          let nr = ''
          if ('task_nr' in nodeDetails) {
            nr = nodeDetails.task_nr
            return `${nr} - ${nodeDetails.qualified_name}`
          }
          return nodeDetails.qualified_name
        })

        // Add labels for the nodes
        nodeEnter
          .append('text')
          .attr('dy', '.35em')
          .attr('x', d => (d.children || d._children ? -13 : 13))
          .attr('text-anchor', d => (d.children || d._children ? 'end' : 'start'))
          .text(d => {
            const nodeDetails = vueApp.node_lookup[d.data.id]
            if ('task_nr' in nodeDetails) {
              return `${nodeDetails.task_nr} - ${nodeDetails.name}`
            }
            return nodeDetails.name
          })

        // UPDATE
        const nodeUpdate = nodeEnter.merge(node)

        // Transition to the proper position for the node
        nodeUpdate
          // .transition()
          // .duration(duration)
          .attr('transform', d => `translate(${d.y},${d.x})`)

        // Update the node attributes and style
        nodeUpdate.select('circle').attr('r', 10).attr('cursor', 'pointer')

        // Remove any exiting nodes
        const nodeExit = node
          .exit()
          // .transition()
          // .duration(duration)
          .attr('transform', d => `translate(${source.y},${source.x})`)
          .remove()

        // On exit reduce the node circles size to 0
        nodeExit.select('circle').attr('r', 1e-6)

        // On exit reduce the opacity of text labels
        nodeExit.select('text').style('fill-opacity', 1e-6)

        // ****************** links section ***************************

        // Update the links...
        const link = svg.selectAll('path.link').data(links, d => d.id)

        // Enter any new links at the parent's previous position.
        const linkEnter = link
          .enter()
          .insert('path', 'g')
          .attr('class', 'link')
          .attr('d', d => {
            const o = { x: source.x0, y: source.y0 }
            return diagonal(o, o)
          })

        // UPDATE
        const linkUpdate = linkEnter.merge(link)

        // Transition back to the parent element position
        linkUpdate
          // .transition()
          // .duration(duration)
          .attr('d', d => diagonal(d, d.parent))

        // Remove any exiting links
        const linkExit = link
          .exit()
          // .transition()
          // .duration(duration)
          .attr('d', d => {
            const o = { x: source.x, y: source.y }
            return diagonal(o, o)
          })
          .remove()

        // Store the old positions for transition.
        nodes.forEach(d => {
          d.x0 = d.x
          d.y0 = d.y
        })

        // Menu items and their actions
        const nodeMenuItems = [
          {
            title: () => 'Zoom to this entity',
            action: d => {
              // console.warn('Router stuff: ', vueApp.$route.name)
              if (vueApp.$route.name === 'domain_ecosystem' || vueApp.$route.name === 'domain_ecosystem_focus') {
                vueApp.$router.push({
                  name: 'domain_ecosystem_focus',
                  params: { id: d.data.id, force: 'true' },
                })
                vueApp.emit()
                // vueApp.$router.go();
              } else if (vueApp.$route.name === 'domain_ontology') { // TODO: Change the path to the new MxGraph one when it is migrated
                vueApp.$router.push({ path: `/domain_ontology/${d.data.id}` })
                // vueApp.$forceUpdate();
                vueApp.$router.go()
              }
            },
          },
          {
            title: d => (d._children ? 'Expand' : 'Collapse'),
            action: d => {
              click(d)
              // console.debug('Node Collapse action fired: ', d)
            },
          },
          {
            title: () => 'Add child',
            action: d => {
              vueApp.selParent = d.data.id
              vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
                .then(() => {
                  vueApp.$bvModal.show('add-entity-modal')
                })
            },
          },
          {
            title: () => 'Delete node',
            action: d => {
              vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
                .then(() => {
                  vueApp.$bvModal.show('delete_entity')
                })
            },
          },
        ]

        function diagonal(s, d) {
          const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
            ${(s.y + d.y) / 2} ${d.x},
            ${d.y} ${d.x}`

          return path
        }

        function click(d) {
          vueApp.$emit('sidebar', true)
          vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
          if (d.children) {
            d._children = d.children
            d.children = null
          } else {
            d.children = d._children
            d._children = null
          }
          update(d)
        }
      }
    },

    clearSVG() {
      d3.select('#graph_svg').remove()
    },

    emit() {
      this.$emit('loaded')
    },

    safeJSON(data) {
      // eslint-disable-next-line global-require
      const safeJsonStringify = require('safe-json-stringify')
      // console.debug('Unsafe version: ', data)
      // console.debug('Safe version: ', safeJsonStringify(data))

      return safeJsonStringify(data)
    },

    postAdd(node) {
      const vueApp = this
    },

    postDel(node) {
      const vueApp = this
    },
  },
}
</script>
<style lang="scss">
.dark-layout {
  #d32DGraph {
    .node text {
      font: 14px sans-serif;
      fill: wheat;
      stroke: rgb(0, 0, 0, 0.2);
      stroke-width: 0.5;
    }
    .link {
      fill: none;
      stroke: rgb(109, 109, 109);
      stroke-width: 2px;
    }
  }
}
#d32DGraph {
  .node circle:hover {
    stroke: wheat;
    stroke-width: 3px;
  }

  .node text {
    font: 14px sans-serif;
    fill: black;
    stroke: rgb(0, 0, 0, 0.2);
    stroke-width: 0.5;
  }

  .link {
    fill: none;
    stroke: rgb(196, 196, 196);
    stroke-width: 2px;
  }

  .root {
    fill: orangered;
  }

  .abstract {
    fill: orange;
  }

  .node {
    fill: wheat;
  }

  /* Context menu Classes */
  .contextMenu {
    stroke: black;
    fill: #fff;
  }

  .menuEntry {
    cursor: pointer;
  }

  .menuEntry text {
    font-size: 16px;
  }
}
</style>
